import { Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const columns = [
  {
    dataIndex: 'id', title: 'ID',
    render: id => { return <Link to={`/subscriptiontype/edit/${id}`}>{id}</Link>; },
  },
  { dataIndex: 'brand', title: 'Brand' },
  { dataIndex: 'reference', title: 'Reference' },
];

const FeatureFormTypeLinks = ({ featureFormTypeLinks }) => {
  return (
    <Table
      columns={columns}
      dataSource={featureFormTypeLinks}
      rowid={record => record.id}
    />
  );
};

export default FeatureFormTypeLinks;
