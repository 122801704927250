import base from './base';

const criteria = {
  Age: {
    operations: ['Equals', 'NotEquals', 'SmallerOrEquals', 'Smaller', 'GreaterOrEquals', 'Greater'],
    parameters: [''],
  },
  Person: {
    operations: base.allOperations,
    parameters: [
      'Prospect',
      'CurrentSubscriber',
      'FormerSubscriber7Months',
      'FormerSubscriber12Months',
      'Frozen',
      'Greylisted',
      'Vendor',
    ],
  },
  SubscriptionKind: {
    operations: base.allOperations,
    parameters: [
      'Free',
      'Action',
      'Promo',
      'FullPrice',
      'LinkedSale',
      'Incentive',
      'Student',
      'Teacher',
      'FreeMonth',
      'Senior',
    ],
  },
  Formula: {
    operations: ['Equals', 'NotEquals', 'In', 'NotIn'],
    parameters: [''],
  },
};
const constants = {
  criteria,
};

export default constants;
