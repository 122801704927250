import {
  Checkbox,
  Flex,
  Select,
  Textarea,
  TextField,
} from '@mediahuis/chameleon-react';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { boolean, object, string } from 'yup';

const BooleanRequirementFormSchema = object().shape({
  description: string(),
  errorMessage: string(),
  name: string().required(),
  required: boolean(),
  uniqueKey: string(),  
});

const BooleanRequirementForm = ({
  disabled,
  formRef,
  initialValues,
  onSubmit,
}) => {
  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={BooleanRequirementFormSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <Flex flexDirection="column" style={{ gap: '0.5rem' }}>
          <Field name="name">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="NameInput"
                label="Name"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                required
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="description">
            {({ field, meta }) => (
              <Textarea
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="DescriptionInput"
                label="Description"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                rows={3}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="errorMessage">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="ErrorMessageInput"
                label="Error Message"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="newsletter">
            {({ field, meta }) => (
              <TextField
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="NewsletterInput"
                label="Newsletter"
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="uniqueKey">
            {({ field, meta }) => (
                <Select
                    disabled={disabled}
                    error={!!meta.touched && !!meta.error}
                    id="UniqueKeySelect"
                    label="Unique Key"
                    message={meta.touched && meta.error ? meta.error : undefined}
                    name={field.name}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                >
                  <option value="">none</option>
                  <option value="email">email</option>
                  <option value="phone">phone</option>
                </Select>
            )}
          </Field>

          <Field name="required">
            {({ field, meta }) => (
              <Checkbox
                checked={field.value}
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="RequiredCheck"
                label="Required"
                labelProps={{
                  size: 'Caption1',
                }}
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>

          <Field name="isPrechecked">
            {({ field, meta }) => (
              <Checkbox
                checked={field.value}
                disabled={disabled}
                error={!!meta.touched && !!meta.error}
                id="isPrecheckedCheck"
                label="Prechecked"
                labelProps={{
                  size: 'Caption1',
                }}
                message={meta.touched && meta.error ? meta.error : undefined}
                name={field.name}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          </Field>
        </Flex>
      </Form>
    </Formik>
  );
};

export default BooleanRequirementForm;
